<template>
  <div class="grid place-items-center h-screen">

      <div class="vx-logo flex justify-items-center justify-center items-center mb-2 pt-4">
        <logo :logo="institutionLogo"/>
      </div>

      <div class="flex w-full">

        <vx-card class="m-24">
          <div>

            <div v-html-safe="campaign ? campaign.description : ''"></div>
            <vs-divider/>

            <div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full">
                  <vs-input
                    class="w-full"
                    :label="$t('marketing.form_lead.name')"
                    v-model="model.name"
                    name="name"
                    v-validate="'required|max:191'"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('name')">
                    {{ errors.first('name') }}
                  </span>
                </div>
              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full">
                  <vs-input
                    type="email"
                    name="email"
                    v-model="model.email"
                    class="w-full"
                    :label="$t('marketing.form_lead.email')"
                    v-validate="'required|email'"
                    data-vv-validate-on="blur"
                  />
                  <span class="text-danger text-sm" v-show="errors.has('email')">
                    {{ errors.first('email') }}
                  </span>
                </div>
              </div>

              <div class="vx-row mb-6">
                <div class="vx-col w-full">
                  <div class="pb-1 pt-1">
                    <vs-input
                      data-vv-validate-on="blur"
                      id="phone"
                      name="phone"
                      v-model="model.phone"
                      class="w-full"
                      :label="$t('marketing.form_lead.phone')"
                      v-mask="['(##) ####-####', '(##) #####-####']"
                    />
                    <span class="text-danger text-sm">{{ errors.first('phone') }}</span>
                  </div>
                  <span class="text-danger text-sm" v-show="errors.has('phone')">
                    {{ errors.first('phone') }}
                  </span>
                </div>
              </div>

              <div class="vx-row mt-4">
                <div class="vx-col w-full">

                  <vue-recaptcha
                    ref="invisibleRecaptcha"
                    @verify="onSubmit"
                    @expired="onExpired"
                    size="invisible"
                    :sitekey="recaptchaSiteKey"
                    :loadRecaptchaScript="true"
                  >
                    <vs-button native-type="submit" class="float-right" :disabled="!validateForm">
                      {{ $t("marketing.form_lead.register") }}
                    </vs-button>
                  </vue-recaptcha>


                </div>
              </div>

            </div>
          </div>
        </vx-card>
      </div>

  </div>
</template>

<script>
import CampaignService from '@/services/api/CampaignService'
import Logo from '@/layouts/components/Logo.vue'
import { VueRecaptcha } from 'vue-recaptcha'

export default {
  components: {
    Logo,
    VueRecaptcha
  },
  props: {
    code: {
      type: String,
      default: null
    }
  },
  data: () => ({
    recaptchaVerified: false,
    service: null,
    campaign: null,
    model: {
      name: '',
      email: '',
      phone: ''
    }
  }),
  computed: {
    validateForm() {
      return (
        !this.isEmpty(this.campaign) &&
        !this.isEmpty(this.model.name) &&
        !this.isEmpty(this.model.email)
      )
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCH_SITE_KEY
    },
    // eslint-disable-next-line vue/return-in-computed-property
    institutionLogo() {
      if (this.campaign
            && this.campaign.questionnaire
              && this.campaign.questionnaire.institution
                && this.campaign.questionnaire.institution.organization) {
        return this.campaign.questionnaire.institution.organization.logo
      }
    }
  },
  methods: {
    onSubmit(e) {
      this.$vs.loading()
      this.$refs.invisibleRecaptcha.execute()

      this.service
        .createLead(
          this.campaign.id,
          {
            name: this.model.name,
            email: this.model.email,
            phone: this.model.phone
          }
        )
        .then(
          data => {
            const payload = {
              user: data,
              updateUsername: true,
              userDetails: {
                email: data.email,
              },
              notify: this.$vs.notify,
              closeAnimation: this.$vs.loading.close,
              acl: this.$acl,
              callbackSuccess: () => {
                this.$vs.loading.close()
                this.notifySuccess(this.$vs, this.$t('inscricao-realizada-com-sucesso', 10000))
                window.location.href = `${this.campaign.questionnaire.short_url}/lead/${data.id}`
              },
              redirect: false
            }

            console.log('payload...', payload)
            this.$store.dispatch('auth/loginSuccess', payload)
          },
          error => {
            this.$vs.loading.close()
            this.$refs.invisibleRecaptcha.reset()
            this.showErrors(this.$validator, error)
            this.notifyError(
              this.$vs,
              this.$t('nao-foi-possivel-realizar-esta-operacao')
            )
          }
        )
    },
    onExpired: function () {
      console.log('Expired')
    },
    getCampaignByCode() {
      this.$vs.loading()
      this.service.getCampaignByCode(this.code).then(
        data => {
          this.title = data.title
          this.campaign = data
          this.$vs.loading.close()
        },
        error => {
          this.$vs.loading.close()
        }
      )
    },
  },
  beforeMount() {
    this.service = CampaignService.build(this.$vs)
    this.getCampaignByCode()
  }
}
</script>
